import Component from 'classes/Component';

export default class Preloader extends Component {
  constructor({ template }) {
    super({
      element: '.navigation',
    });
  }
  onChange(template) {
    template === 'home'
      ? this.element.classList.remove('navigation--application')
      : this.element.classList.add('navigation--application');
  }
}
