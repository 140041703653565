import each from "lodash/each";

import Component from "classes/Component";

export default class extends Component {
  constructor({ elements }) {
    super({
      elements,
    });
  }

  onInputChange() {
    this.inputField = this.querySelector(".upload__wrapper__field");
    this.inputLabel = this.querySelector(".upload__wrapper__label span");
    this.iconUpload = this.querySelector(".upload__wrapper__icon");
    this.iconCancel = this.querySelector(".upload__wrapper__cancel");

    this.fileName = "";

    this.iconCancel.addEventListener("click", () => {
      this.inputField.value = "";
      this.iconUpload.classList.remove("hidden");
      this.iconCancel.classList.add("hidden");
      this.inputLabel.classList.remove("filled");
      this.inputLabel.parentElement.classList.remove("pointer-events-none");
      this.inputLabel.textContent = "Single PDF File, max 2MB.";
    });

    if (this.inputField.files[0]) {
      this.fileSize = this.inputField.files[0].size / 1024 / 1024;
      this.fileName = this.inputField.files[0].name.split("\\").pop();
      this.fileExt =
        this.fileName.substring(
          this.fileName.lastIndexOf(".") + 1,
          this.fileName.length
        ) || this.fileName;
      if (this.fileSize > 2) {
        alert("File size exceeds 2 MB");
        this.inputField.value = "";
      } else if (this.fileExt != "pdf") {
        alert("Please use a PDF file");
        this.inputField.value = "";
      } else if (this.inputField.files[0]) {
        this.iconUpload.classList.add("hidden");
        this.iconCancel.classList.remove("hidden");
        this.inputLabel.parentElement.classList.add("pointer-events-none");
        this.inputLabel.classList.add("filled");
        this.inputLabel.textContent = this.fileName;
      }
    } else {
      this.iconUpload.classList.remove("hidden");
      this.iconCancel.classList.add("hidden");
      this.inputLabel.classList.remove("filled");
      this.inputLabel.parentElement.classList.remove("pointer-events-none");
      this.inputLabel.textContent = "Single PDF File, max 2MB.";
    }
  }

  toggleLock() {
    each(this.elements.inputUpload, (element) => {
      if (element.id === "poster") {
        this.lockedInput = element;
      }
    });
    this.lockedInputWrapper = this.lockedInput.parentElement.parentElement;
    if (this.elements.inputUploadTrigger[0].checked) {
      this.lockedInput.disabled = false;
      this.lockedInputWrapper.classList.toggle("disabled");
    } else {
      this.inputField = this.lockedInputWrapper.querySelector(
        ".upload__wrapper__field"
      );
      this.inputLabel = this.lockedInputWrapper.querySelector(
        ".upload__wrapper__label span"
      );
      this.iconUpload = this.lockedInputWrapper.querySelector(
        ".upload__wrapper__icon"
      );
      this.iconCancel = this.lockedInputWrapper.querySelector(
        ".upload__wrapper__cancel"
      );
      this.inputLabel.parentElement.classList.remove("pointer-events-none");
      this.iconUpload.classList.remove("hidden");
      this.iconCancel.classList.add("hidden");
      this.inputLabel.classList.remove("filled");
      this.inputLabel.textContent = "Single PDF File, max 2MB.";
      this.lockedInput.value = "";
      this.lockedInput.onInputChange;
      this.lockedInput.disabled = true;
      this.lockedInputWrapper.classList.toggle("disabled");
    }
  }

  addEventListeners() {
    each(this.elements.inputUploadWrapper, (element) => {
      element.addEventListener("change", this.onInputChange);
    });
    each(this.elements.inputUploadTrigger, (element) => {
      element.addEventListener("change", this.toggleLock.bind(this));
    });
  }
}
