import each from 'lodash/each';

import Component from 'classes/Component';

export default class extends Component {
  constructor({ element, elements }) {
    super({
      element,
      elements,
    });

    this.baseUrl = 'https://rolemodel.bendingspoons.com';
    this.eventId = '60755a1cdaf8fcf4c6a41c89';
    this.errors = '';

    this.fetchDeadline();
  }

  fetchDeadline(testingDate) {
    const todayDate = testingDate ? new Date(testingDate) : new Date();
    let beforeDeadline = false;
    let afterDeadline = false;
    let eventStarted = false;
    fetch(`${this.baseUrl}/events/${this.eventId}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        const applicationClose = new Date(data.close_at);
        const applicationOpening = new Date(data.open_at);
        beforeDeadline = applicationOpening > todayDate;
        afterDeadline = applicationClose < todayDate;
        eventStarted = data.are_applications_open;
        if (!eventStarted && beforeDeadline) {
          each(this.elements.applyButton, (element) => {
            element.textContent = 'Coming Soon';
            element.classList.add('disabled');
            element.disabled = true;
          });
          each(this.elements.deadlineText, (element) => {
            element.textContent = `Applications open May 25, 2022`;
          });
          return false;
        }
        if (eventStarted) {
          each(this.elements.deadlineText, (element) => {
            element.textContent = `Applications close July 25, 2022!`;
          });
          return true;
        }
        if (!eventStarted && afterDeadline) {
          each(this.elements.applyButton, (element) => {
            element.textContent = 'Closed';
            element.classList.add('disabled');
            element.disabled = true;
          });
          each(this.elements.deadlineText, (element) => {
            element.textContent = 'This edition is over. See you next time!';
          });
          return false;
        }
      })
      .catch((error) => {
        console.log('Error:', error);
        return false;
      });
  }

  addEventListeners() {}
}
