import 'particles.js';
import particlesJson from 'particlesjs-config.json';

import Page from 'classes/Page';

import SetButtons from './SetButtons';

export default class Home extends Page {
  constructor() {
    super({
      id: 'homepage',
      element: '.homepage',
      elements: {
        wrapper: '.homepage .page__wrapper',
        navigation: document.querySelector('.navigation'),
        applyButton: '.button',
        deadlineText: '.deadline-text',
      },
    });
  }

  create() {
    super.create();

    particlesJS('particles-js', particlesJson);

    this.setButtons = new SetButtons({
      elements: {
        applyButton: this.elements.applyButton,
        deadlineText: this.elements.deadlineText,
      },
    });
  }
}
