export const saveTrackingData = () => {
  // calculate the query string
  const qs = ((a) => {
    if (a == "") return {};
    let b = {};
    for (let i = 0; i < a.length; ++i) {
      const p = a[i].split("=", 2);
      if (p.length == 1) b[p[0]] = "";
      else b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
    }
    return b;
  })(window.location.search.substring(1).split("&"));

  // save tracking data
  if (qs && window.localStorage) {
    const utm_source =
      qs.utm_source || localStorage.getItem("utm_source") || "";
    const utm_medium =
      qs.utm_medium || localStorage.getItem("utm_medium") || "";
    const utm_term = qs.utm_term || localStorage.getItem("utm_term") || "";
    const utm_content =
      qs.utm_content || localStorage.getItem("utm_content") || "";
    const utm_campaign =
      qs.utm_campaign || localStorage.getItem("utm_campaign") || "";

    localStorage.setItem("utm_source", utm_source);
    localStorage.setItem("utm_medium", utm_medium);
    localStorage.setItem("utm_term", utm_term);
    localStorage.setItem("utm_content", utm_content);
    localStorage.setItem("utm_campaign", utm_campaign);
  }
};
