import each from 'lodash/each';
import GSAP from 'gsap';

import { validateEmail } from 'utils/input';

import Component from 'classes/Component';

export default class extends Component {
  constructor({ element, elements }) {
    super({
      element,
      elements,
    });

    this.baseUrl = 'https://rolemodel.bendingspoons.com';
    this.allFilled = false;
    this.errors = '';
    this.roleId = '';
    this.eventId = '60755a1cdaf8fcf4c6a41c89';

    fetch(`${this.baseUrl}/events/${this.eventId}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        this.eventStarted = data.are_applications_open;
      });
  }

  onTextChange() {
    this.isFilled();
  }

  onInputChange() {
    this.isFilled();
  }

  isFilled() {
    this.allFilled = true;
    if (this.elements.inputText) {
      each(this.elements.inputText, (element) => {
        if (!element.value && element.classList.contains('required'))
          this.allFilled = false;
      });
    }
    if (this.elements.inputUpload) {
      each(this.elements.inputUpload, (element) => {
        if (!element.value && element.classList.contains('required'))
          this.allFilled = false;
      });
    }
    if (this.elements.inputRadio) {
      each(this.elements.inputRadio, (element) => {
        if (
          !this.elements.form[0].elements[element.id].value &&
          element.classList.contains('required')
        )
          this.allFilled = false;
      });
    }
    if (this.elements.inputCheckbox) {
      each(this.elements.inputCheckbox, (element) => {
        if (!element.checked && element.classList.contains('required'))
          this.allFilled = false;
      });
    }
    this.updateButton();
  }

  updateButton() {
    this.allFilled && this.eventStarted
      ? (this.elements.button[0].disabled = false)
      : (this.elements.button[0].disabled = true);
  }

  handleErrors = (response) => {
    if (!response.ok) throw new Error(response.status);
    return response;
  };

  sendForm() {
    const email = document.getElementById('email');
    validateEmail(email.value)
      ? (this.errors = '')
      : (this.errors = 'Please enter a correct email');
    let formdata = new FormData();

    let applicantData = {
      utm_source: localStorage.getItem('utm_source') || '',
      utm_medium: localStorage.getItem('utm_medium') || '',
      utm_term: localStorage.getItem('utm_term') || '',
      utm_content: localStorage.getItem('utm_content') || '',
      utm_campaign: localStorage.getItem('utm_campaign') || '',
    };
    // add applicant data
    if (this.elements.inputText) {
      each(this.elements.inputText, (element) => {
        if (element.name === 'name') {
          applicantData['57588b958985f10010fe5e14'] = element.value;
        }
        if (element.name === 'surname') {
          applicantData['57588b9e8985f10010fe5e15'] = element.value;
        }
        if (element.name === 'email') {
          applicantData['57588bac8985f1000e166a34'] = element.value;
        }
      });
    }

    if (this.elements.inputRadio) {
      each(this.elements.inputRadio, (element) => {
        if (element.id === 'source') {
          applicantData['62737fe58f056baf73e2f0f9'] =
            this.elements.form[0].elements[element.id].value;
        }
        if (element.id === 'interest') {
          applicantData['62737ddfa4e3ec3ece41b7ad'] =
            this.elements.form[0].elements[element.id].value;
        }
        if (element.id === 'edition') {
          this.roleId = this.elements.form[0].elements[element.id].value;
        }
      });
    }

    formdata.append('applicant_data', JSON.stringify(applicantData));

    const campaignNumber = localStorage.getItem('utm_campaign') || '';
    if (campaignNumber) {
      formdata.append('campaign_number', campaignNumber);
    }

    // add files
    if (this.elements.inputUpload) {
      each(this.elements.inputUpload, (element) => {
        if (element.name === 'cv') {
          if (element.files[0]) {
            formdata.append('57588c328985f1000e166a35', element.files[0]);
          }
        }
        if (element.name === 'cover') {
          if (element.files[0]) {
            formdata.append('57588c0c8985f1000dbecd17', element.files[0]);
          }
        }
        if (element.name === 'transcripts') {
          if (element.files[0]) {
            formdata.append('5cfa1d05f371c4824d84afc3', element.files[0]);
          }
        }
      });
    }

    // Application flow
    this.elements.button[0].disabled = true;
    if (this.errors) {
      alert(this.errors);
      this.elements.button[0].disabled = false;
    } else {
      fetch(`${this.baseUrl}/roles/${this.roleId}`, {
        method: 'POST',
        body: formdata,
      })
        .then(this.handleErrors)
        .then((data) => {
          this.successAnimation();
        })
        .catch((error) => {
          alert(error);
          console.log(error);
        });
    }
  }

  successAnimation() {
    const fields = this.elements.form[0].querySelector('.fields');
    this.animationOut = GSAP.timeline();

    this.animationOut.to(fields.children, {
      stagger: 0.2,
      duration: 0.4,
      y: -10,
      autoAlpha: 0,
      ease: 'expo.out',
    });

    this.animationOut.to(
      this.elements.button,
      {
        duration: 0.6,
        y: -10,
        autoAlpha: 0,
        height: 0,
        ease: 'expo.out',
        onComplete: () => {
          this.elements.successWrapper[0].classList.remove('hidden');
        },
      },
      '-=0.3'
    );
    this.animationOut.to(
      this.elements.successWrapper[0],
      {
        duration: 0.6,
        autoAlpha: 1,
        ease: 'expo.out',
      },
      '-=0.1'
    );
    this.animationOut.to(
      this.elements.form[0],
      {
        duration: 0.5,
        height: 0,
        ease: 'expo.out',
        onComplete: () => {
          this.elements.form[0].parentNode.removeChild(this.elements.form[0]);
          this.elements.button[0].parentNode.removeChild(
            this.elements.button[0]
          );
        },
      },
      '-=0.6'
    );
  }

  addEventListeners() {
    each(this.elements.inputText, (element) => {
      element.addEventListener('input', this.onTextChange.bind(this));
    });
    each(this.elements.inputCheckbox, (element) => {
      element.addEventListener('change', this.onInputChange.bind(this));
    });
    each(this.elements.inputRadio, (element) => {
      element.addEventListener('change', this.onInputChange.bind(this));
    });
    each(this.elements.inputUpload, (element) => {
      element.addEventListener('change', this.onInputChange.bind(this));
      element.parentNode
        .querySelector('.upload__wrapper__cancel')
        .addEventListener('click', () => {
          setTimeout(this.onInputChange.bind(this), 50);
        });
    });
    each(this.elements.button, (element) => {
      element.addEventListener('click', this.sendForm.bind(this));
    });
  }
}
