import Page from 'classes/Page';

import Form from 'components/Form';
import Upload from 'components/Upload';

export default class Application extends Page {
  constructor() {
    super({
      id: 'application',
      element: '.application',
      elements: {
        wrapper: '.application .page__wrapper',
        navigation: document.querySelector('.navigation'),
        form: '.application-form',
        inputText: '.text__wrapper__field',
        inputUpload: '.upload__wrapper__field',
        inputUploadWrapper: '.upload__wrapper',
        inputRadio: '.radio__wrapper__fieldset',
        button: '#apply_button',
        successWrapper: '.success__wrapper',
      },
    });
  }

  create() {
    super.create();

    this.upload = new Upload({
      elements: {
        inputUpload: this.elements.inputUpload,
        inputUploadWrapper: this.elements.inputUploadWrapper,
      },
    });

    this.form = new Form({
      elements: {
        form: this.elements.form,
        inputText: this.elements.inputText,
        inputUpload: this.elements.inputUpload,
        inputRadio: this.elements.inputRadio,
        button: this.elements.button,
        successWrapper: this.elements.successWrapper,
      },
    });
  }
}
